@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700;900&family=Poppins:wght@400;500;600;700;900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900display=swap');


*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root{

  /* Colors */
   /* --btn-bg: #36A148; */
   --btn-bg: #42d35a;
   --shape-color:#f1b6b9;
   --white-color: #ffffff;
   --black-color: #000000;
   --sidebar-color: #1B1F22;
   /* --sidebar-textcolor: #A4A8AD;  */
   --sidebar-textcolor: #ffffff; 
   --card-colorGradient1: linear-gradient(to right, #14BBB6 , #EF3850);
   --card-colorGradient2: linear-gradient(to right, #1B5D9F , #51BF9D);
   --card-colorGradient3: linear-gradient(to right, #4A1448 , #BD4A4E);
   --card-colorGradient4: linear-gradient(to right, #3488C6 , #7074B7);


   /* Grey Color */
   --gray-50: #f9fafb;
   --gray-100: #f3f4f6;
   --gray-200: #e5e7eb;
   --gray-300: #d1d5db;
   --gray-400: #9ca3af;
   --gray-500: #6b7280;
   --gray-600: #4b5563;
   --gray-700: #374151;
   --gray-800: #1f2937;
   --gray-900: #111827;
   --gray-950: #030712;

   /* border shadow */
   --box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);


}


html {font-size: 16px;} 

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
  background: white;
  line-height: 1.75;
  color: var(--gray-950);
  overflow-x: hidden;
  background-color: var(--gray-100);
}

p {
  font-size: 1.3rem;

}

h1, h2, h3, h4, h5 {
  margin: 0rem 0 1.38rem;
  font-family: "Merriweather", serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 2.5rem;
}

h2 {font-size: 2rem;}

h3 {font-size: 1.5rem;}

h4 {font-size: 1.363rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

.bold{
  font-weight: bold;
}

.italic{
  font-style: italic;
}

.RootLayout{
  display: flex;
  justify-content: space-between;
}

/* SIDEBAR */
header{
  width: 280px;
  background-color: var(--sidebar-color);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--gray-800) var(--sidebar-color);
}

.hideSideBar{
  width: 70px;
  overflow-y: hidden;
}

.showSideBar{
  width: 300px;
}

.sideBarHeader{
  width: 100%;
  padding: 1em .5em;
  flex-direction: column;
  color: var(--sidebar-textcolor);
}

.sideBarHeader div{
  border-radius: 5px;
  margin-top: .5em;
  padding: .3em;
}

.Logo{
  width: 100%;
  font-size: 1.5em;
}

.logoImg{
  width: 100%;
}

.profileContainer{
  display: flex;
  align-items: center;
}

.profilePic{
  background-color: var(--btn-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  margin-right: .3em;
}

.profilePic,.profileName{
  font-size: 1rem;
}

.sidebar{
  width: 100%;
  height: 100vh;
  padding: 0em .5em;
  display: flex;
  flex-direction: column;
  color: var(--sidebar-textcolor);
  margin-top: .5em;
}

.mainMenu{
  position: relative;
  display: flex;
  justify-content: left;
  color: var(--sidebar-textcolor);
  text-decoration: none;
  width: 100%;
  padding: .5em; 
  border-radius: 5px;
  align-items: center;
  font-size: 1rem;
  margin-bottom: .5rem;cursor: pointer;

}

.mainMenu:hover{
  background-color: var(--btn-bg);
  transition: all .5s ease-in-out; 
}

.menuName{
  margin-left: .5em;
}

.menuIcon{
  font-size: 1.6rem;
}

.dropDown{
  float: right;
  position: absolute;
  right: 2px;
  transform: rotate(90deg);
}

.showDropDown{
  transform: rotate(0deg);
}

.eachLink{
  width: 100%;
  overflow: hidden;
}

.subMenu{
  width: calc(100% - 3em);
  margin-left: 3em;
}

.subMenu .link{
  font-size: .9rem;
}

.active{
  background-color: var(--btn-bg);
}

.unActive{
  background-color: transparent;
}

.mainActive{
  color: var(--btn-bg);
}

.mainActive:hover{
  color: var(--sidebar-textcolor);
}

.mainUnActive{
   color: var(--sidebar-textcolor);
}

/* MAIN */
main{
  /* min-height: 100vh ; */
  width: 100%;
  margin-left: 300px;
  position: relative;
}

.extendMain{
  margin-left: 70px;
}

.shrinkMain{
  margin-left: 300px;
}

.mainSectionHeader{
  font-size: 1.5rem;
  font-weight: bold;
}


/* HEADER */
.header{
  width: 100%;
  height: 60px;
  background-color: var(--gray-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  box-shadow: var(--box-shadow);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.bar,.account{
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--gray-700)
}

.bar{
  color: var(--btn-bg);
}

.account{
  position: relative;
}

.Logout{
  position: absolute;
  right: .1em;
  top: 2em;
  background-color: var(--gray-100);
  padding: .5em;
  text-align: center;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--gray-300);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.showLogout{
  display: block;
}

.Logout:hover{
  color: var(--btn-bg);
  transition: all .2s ease-in-out;
}

/* TABLES */
table {
  border-collapse: collapse;
  width: 100%;

}

td, th {
  border: 1px solid var(--gray-300);
  text-align: left;
  padding: 8px;
  border-collapse: collapse;

}

.nullError{
  font-weight: 700;
  font-size: 2rem;
}

.tableContainer{
  overflow-x:auto;
  max-width:100%;
}

/* VEHICLES */
.dropDowns{
  width: 230px;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid var(--gray-300);
}

.dropDowns option{
  font-weight: 500;
  background-color: var(--gray-300);
  padding: 1rem;
}

.totalVehicleLists{
  font-weight: 500;
  margin-left: 1em;
}

.departureDropDown{
  margin-bottom: 2em;
}

/* FORM */
.formContainer{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formHeader{
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1em;
}

form{
  background-color: var(--gray-100);
  width: 100%;
  max-width: 700px;
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid var(--btn-bg);
  box-shadow: var(--box-shadow);
  z-index: 2;
}

label{
  padding-bottom: 1em;
}

label,input,.form-input-dropDown{
  width: 100%;
}

.inputContainer{
  display: grid;
 grid-template-columns: 1fr 1fr;
 gap: 2em;
  justify-content: space-between;
  width: 100%;
}

.input-name{
   font-size: 1rem;
}

.form-input{
    padding: .2em .3em;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid var(--btn-bg);
    font-family: 500;
    font-size: 1.1rem;
    width: 100%;
    margin-bottom: 1em;
}

.form-btn{
  background-color: var(--btn-bg);
  font-size: 1rem;
  font-weight: 700;
  color: var(--white-color);
  cursor: pointer;
  padding: .7em .3em;

}

.form-input-dropDown{
  margin-bottom: 1em;
}

.errorMessage{
  color: var(--sidebar-color);
  font-weight: bold;
  display: block;
  margin-bottom: 1em;
}

.editBtn{
   color: var(--btn-bg);
}

.deleteBtn{
   color: rgb(136, 1, 1);
}

.updateModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modalBg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 1;
}

.closeModal{
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
}

.errorPage{
  background-color: var(--btn-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: var(--white-color);
  font-weight: bold;
  font-size: 2rem;
}

/* LOGIN */
.loginElement,.errorPage{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: var(--gray-100);
}

.errorPage{
  background-color: var(--btn-bg);
}

/* CHECK IN-OUT` */
#PlateNumbers{
  width: 100%;
  background-color: red;
}

.sectionHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.filterBy{
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.filterBy div{
  width: 300px;
  margin: 0;
  margin-left: 20px;
  padding: 0;
}

.filterBy div select{
  margin: 0;
}

.overlayContainer,.overlayDepartureContainer{
  background-color: rgba(204, 201, 201, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 4;
  min-height: 100vh;
}

.hideOverlay{
  z-index: 6;
  position: absolute;
  top: 60px;
  right: .5em;
  color: var(--black-color);
  font-weight: 700;
  font-size: 1.2rem;
  cursor: pointer;
}

.overlayDepartureContainer{
  z-index: 5;
  /* background-color: var(--gray-200); */

}

.entranceDropDown{
  width: 70%;
  padding: 1em;
  border-radius: 20px;
  background-color: var(--gray-50);
}

.totalQueueContainer{
  position: relative;
}

.totalQueue{
  position: absolute;
  background-color: var(--gray-50);
  top: 40px;
  right: 10px;
  box-shadow: var(--box-shadow);
  width: 300px;
  border-radius: 20px;
  padding: 10px;
  overflow: hidden;
}

.totalQueueByDestination{
  margin-left: 1rem;
}

.reset-btn{
  width: 100%;
  text-align: center;
  margin: 10px auto;
  margin-top: 10px;
}

.submitCheckOut{
  background-color: var(--btn-bg);
  border-radius: 5px;
  padding: 5px; 
  color: var(--white-color);
  font-weight: bold;
  cursor: pointer;
}

.cancelCheckOut{
  background-color: red;
}

.Queued{
  color: var(--btn-bg);
  font-weight: bold;
}

.queuePosition{
  font-weight: bold;
}

.checkInForm{
  position: relative;
}

.queueInfo{
  position: absolute;
  color: var(--black-color);
  background-color: var(--btn-bg);
  width: 60%;
  height: 80%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  transition: all 1s ease-in-out;
}

.hideQueueInfo{
  transform: scale(0);
}

.showQueueInfo{
  transform: scale(1);
}

.queueInfo p{
  font-size: 1rem;
}

